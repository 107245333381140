.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: url('./bg3.jpg'); /* Replace with actual image path */
  background-size: cover; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Downey Light', sans-serif;
  font-weight: 10;
}