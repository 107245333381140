body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-animation: fade-in-out;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {filter: drop-shadow(7px 7px 4px #999);
}

@keyframes flickerAnimation {
  0%   { opacity:1; transform: scale(1.2);}
  30%  { opacity:0.7; transform: scale(0.9);}
  75% { opacity:0.9; transform: scale(1.2);}
  100% { opacity:1; transform: scale(1.2);}
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; transform: scale(1.2);}
  30%  { opacity:0.7; transform: scale(0.9);}
  75% { opacity:0.9; transform: scale(1.2);}
  100% { opacity:1; transform: scale(1.2);}
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; transform: scale(1.2);}
  30%  { opacity:0.7; transform: scale(0.9);}
  75% { opacity:0.9; transform: scale(1.2);}
  100% { opacity:1; transform: scale(1.2);}
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; transform: scale(1.2);}
  30%  { opacity:0.7; transform: scale(0.9);}
  75% { opacity:0.9; transform: scale(1.2);}
  100% { opacity:1; transform: scale(1.2);}
}
.animate-flicker {
   -webkit-animation: flickerAnimation 7s infinite;
   -moz-animation: flickerAnimation 7s infinite;
   -o-animation: flickerAnimation 7s infinite;
    animation: flickerAnimation 7s infinite;
}

@-moz-keyframes comingSoonAnimation{
  0%   { transform: scale(1);}
  50%  { transform: scale(0.9);}
  100% { transform: scale(1);}
}

@-webkit-keyframes comingSoonAnimation{
  0%   { transform: scale(1);}
  50%  { transform: scale(0.9);}
  100% { transform: scale(1);}
}

@keyframes comingSoonAnimation{
  0%   { transform: scale(1);}
  50%  { transform: scale(0.9);}
  100% { transform: scale(1);}
}

.animate-comingsoon {
  -moz-animation: comingSoonAnimation 1s infinite;
  -webkit-animation: comingSoonAnimation 1s infinite;
  animation: comingSoonAnimation 1s infinite;
}